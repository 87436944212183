// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getDatabase } from 'firebase/database';
import { getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC5BmTwFSnBHFW03yNdzUQJ0ebqyVbp9ZA",
    authDomain: "sativite.firebaseapp.com",
    projectId: "sativite",
    storageBucket: "sativite.appspot.com",
    messagingSenderId: "149456959296",
    appId: "1:149456959296:web:abe816470622760e8feca5",
    measurementId: "G-MS3YZY5JDB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const db = getDatabase(app)
const firestore = getFirestore(app);
const functions = getFunctions(app)
const storage = getStorage(app)
const auth = getAuth();

export function firebaseListener(func) {
    auth.onAuthStateChanged(function(user) {
        if (user) {
            // console.log("User log in success", user);
            func(true, user)
        } else {
            // console.log("User log in failed", user);
            func(false)
        }
    }, function(error) {
        console.log(error)
    });
}

export {
    db,
    firestore,
    functions,
    auth,
    storage
}