<template>
    <Register type="pharmacist"/>
</template>

<script>
// @ is an alias to /src
import Register from '@/components/auth/Register'

export default {
  name: 'RegisterPharmacist',
  components: {
    Register
  }
}
</script>
