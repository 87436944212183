import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../components/Store.vue';
import ShoppingCart from '../components/ShoppingCart.vue';
import ProductDetails from '../components/ProductDetails.vue';
import Login from '../components/auth/Login.vue';
import ViewUser from "@/components/auth/ViewUser";
import RegisterDoctor from "@/views/RegisterDoctor";
import RegisterPharmacist from "@/views/RegisterPharmacist";
import RegisterPatient from "@/views/RegisterPatient";
Vue.use(VueRouter)

const routes = [
/*  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
  }*/
  {path: '/', component: Login, name: 'login'},
  {path: '/store', component: Store, name: 'mainpage'},
  {path: '/product/:id', component: ProductDetails, name: 'product'},
  {path: '/cart', component: ShoppingCart, name: 'shoppingcart'},
  {path: '/login', component: Login, name: 'login', onlyGuest: true },
  {path: '/register-patient', component: RegisterPatient, name: 'registerpatient', onlyGuest: true},
  {path: '/register-pharmacist', component: RegisterPharmacist, name: 'registerpharmacist', onlyGuest: true},
  {path: '/register-doctor', component: RegisterDoctor, name: 'registerdoctor', onlyGuest: true},
  {path: '/viewuser', component: ViewUser, name: 'viewuser'},
  {path: '*', redirect: '/' }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
