import { firestore, auth } from '@/firebase';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";
import {  addDoc, serverTimestamp } from "firebase/firestore";
export const updateCart = ({
  commit
}, {item, orderItemId, quantity, isAdd}) => {
  // TODO: Call service
  commit('UPDATE_CART', {item, orderItemId, quantity, isAdd});
  if (isAdd) {
    let message_obj = {
      message: `Add ${item.title} to cart successfully`,
      messageClass: "success",
      autoClose: true
    }
    commit('ADD_MESSAGE', message_obj);
  }
}
export const updateCartSasDetails = ({commit}, {orderItemId, sasDetails}) => {
  commit('UPDATE_CART_SAS_DETAILS', {orderItemId, sasDetails});
}
export const updateCartTmpSasDetails = ({commit}, {orderItemId, tmpSasDetails}) => {
  commit('UPDATE_CART_TMP_SAS_DETAILS', {orderItemId, tmpSasDetails});
}

export const removeItemInCart = ({commit}, {orderItemId}) => {
	commit('REMOVE_CART_ITEM', {orderItemId});
}

export const registerByEmail = (_, {email, password}) => {
	return createUserWithEmailAndPassword(auth, email, password);
}

export const logout = ({commit}) => {
  commit('SET_CART',  {
      status:'draft',
      cartItemList: [],
      statusHistory: []
  }); // clear current cart
  return auth.signOut();
}

export function loginWithEmail (_, {email, password}) {
  return setPersistence(auth, browserLocalPersistence).then(() => {
      return signInWithEmailAndPassword(auth, email, password)
  })
}

export function listenToProductList({commit}) {
	// return ref.child("products").on('value', (products) => {
	// 	commit('UPDATE_PRODUCT_LIST', products.val());
	// });
    const q = query(collection(firestore, "products"), where("status", "==", "published"));
    return  onSnapshot(q, (querySnapshot) => {
       const products =[];
       querySnapshot.forEach((doc) => {
           products.push(doc.data());
       })
       commit('UPDATE_PRODUCT_LIST', products);
    })
}

export function listenToSASNumberList({commit}) {
    const q = query(collection(firestore, "SASNumbers"));
    return  onSnapshot(q, (querySnapshot) => {
       const SASNumbers =[];
       querySnapshot.forEach((doc) => {
           SASNumbers.push(doc.data());
       })
       commit('UPDATE_SAS_NUMBER_LIST', SASNumbers);
    })
}
/* eslint-disable no-unused-vars */
export function getShoppingCart({commit}, {uid, currentCart}) {
/*	if (uid) {
		return ref.child("cart/" + uid).once('value').then((cart) => {
			// console.log(cart.val());
			if (cart.val() && (!currentCart || currentCart.length === 0)) {
				commit('SET_CART', cart.val());
			}
		});
	} else {
		// console.log("User has not logged in");
	}*/
}

export function saveShoppingCart(_, {uid, cartItemList}) {
	// console.log("ACTIONS saveShoppingCart");
	// console.log("CART DATA", cartItemList);
	// return ref.child("cart/" + uid).set(cartItemList);
}

export async function saveToTransaction(_, {uid, cartItemList, status}) {
    /*	let newTransactionKey = ref.child("transactions").push().key;
        var newTransaction = {}
        newTransaction['/transactions/' + uid + '/' + newTransactionKey] = cartItemList;
        return ref.update(newTransaction);*/
    await addDoc(collection(firestore, "orders"), {
        uid,
        createDate: serverTimestamp(),
        status,
        statusHistory: {status, timestamp:serverTimestamp()},
        cartItemList
    });
}
export async function createUserRegistration(_, {registrationDetails}) {
    await addDoc(collection(firestore, "users"), {
        ...registrationDetails,
        createDate: serverTimestamp(),
    });
}
/* eslint-enable no-unused-vars */