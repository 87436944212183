import { auth } from '@/firebase';

const state = {
	isLoggedIn: auth.currentUser != null,
	user: auth.currentUser,
}

const mutations = {
	'AUTH_STATUS_CHANGE' (state) {
		state.isLoggedIn = auth.currentUser != null;
		state.user = auth.currentUser;
	}
}

const actions = {

}

const getters = {
	isLoggedIn: (state) => {
		return state.isLoggedIn;
	},
	currentUser: (state) => {
		if (state && state.user) {
			return {
				ruemail: state.user.email,
				emailVerified: state.user.emailVerified,
				uid: state.user.uid
			}
		} else {
			return {};
		}
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}
