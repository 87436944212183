const state = {
	isLoading: true,
	sasNumbers: [
	]
}

const mutations = {
	'UPDATE_SAS_NUMBER_LIST' (state, sasNumberList) {
		state.sasNumbers = sasNumberList;
		state.isLoading = false;
	}
}

const actions = {

}

const getters = {
	sasNumbers: (state) => {
		return state.sasNumbers;
	},
	isSasNumbersLoading: (state) => {
		return state.isLoading;
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}