<template>
  <tr>
    <td data-th="Product">
<!--      <v-select
      :items="products"
      v-model="selectedProduct"
      v-on:change="updateOrder"
      >
        <template v-slot:selection="{ item }">
          <img :src="item.thumbnail_url" alt="...">{{ item.title }}</template>
        <template v-slot:item="{ item }">
          <img :src="item.thumbnail_url" alt="...">{{ item.title }}</template>
      </v-select>-->
			<div class="row">
        <div class="col-sm-2 d-none d-sm-block">
          <img :src="cartItem.thumbnail_url" alt="..." class="img-fluid"/>
        </div>
        <div class="col-sm-10">
          <h4 class="nomargin">{{ cartItem.title }}</h4>
          <p>{{ cartItem.description }}</p>
          <p>{{ cartItem.price| toCurrency }}</p>
        </div>
      </div>
<!--      <b-form-select v-model="selectedProduct" :options="options"  v-on:change="updateOrder"></b-form-select>-->
    </td>
    <td data-th="Quantity">
      <input type="number" class="form-control text-center"
        :value="cartItem.quantity"
        @input="updateQuantity"
        min="0">
    </td>
    <td data-th="SAS Number">
<!--      <b-form-input
          id="sas-number"
          :state="validateSASNumber"
          placeholder="Enter SAS Number"
          aria-describedby="input-live-feedback"
          v-model="sasNumber"
      ></b-form-input>-->


<!--      <b-form-invalid-feedback id="input-live-feedback">
        Enter a valid SAS Number
      </b-form-invalid-feedback>-->
      <div v-if="!editSasDetails">

      <p v-if="this.cartItem.sasDetails">
        SAS Number :  <strong>{{this.cartItem.sasDetails.sas_number}}</strong> <br>
        Name: <strong>{{this.cartItem.sasDetails.patient}}</strong>
        Gender: <strong>{{this.cartItem.sasDetails.gender}}</strong><br>
      Date of Birth: <strong>{{this.cartItem.sasDetails.dob| formatFirebaseDate}}</strong><br>
      Doctor: <strong>{{this.cartItem.sasDetails.doctor}}</strong> ({{this.cartItem.sasDetails.ahpra_number}})
      </p>
        <p v-else>
          SAS Number :  <strong>{{this.cartItem.tmpSasDetails.sas_number}}</strong> <br>
          Document: <strong>{{decodeURIComponent(this.cartItem.tmpSasDetails.doc_url).split('/').pop().split('#')[0].split('?')[0]}}</strong></p>
      <button class="btn btn-secondary btn-sm" @click="enableEdit"><i class="fa fa-pencil"></i></button>
      </div>
      <div v-else>
        <vue-typeahead-bootstrap
            v-model="sasNumber"
            :data="sasNumbers"
            :serializer="item => item.sas_number"
            @hit="setSasDetails"
        >
          <template slot="append">
            <button v-if="validateSASNumber" class="btn btn-success btn-sm" @click="setSasDetails"><i class="fa fa-plus-circle"></i></button>
            <button v-else-if="fileAdded" class="btn btn-warning btn-sm"><i class="fa fa-plus-circle"></i></button>
            <button v-else class="btn btn-danger btn-sm"><i class="fa fa-times-circle"></i></button>
          </template>
        </vue-typeahead-bootstrap>
        <div v-if="!validateSASNumber">
        <label >TGA Approval Letter</label>
      <b-form-file
          v-model="file"
          :state="Boolean(file)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          aria-describedby="input-live-feedback"
          accept="application/pdf"
          v-on:input="uploadDocument"
      ></b-form-file>
        <b-progress :value="uploadProgress" :max="100" show-progress animated :label="uploadProgress+ '%'"></b-progress>
        <b-form-invalid-feedback id="input-live-feedback">
          Upload the TGA approval as a single file in PDF form
        </b-form-invalid-feedback>
          <button
              class="btn btn-primary btn-sm"
              @click="updateTmpSasDetails"
              :disabled="!fileAdded"
          >Upload Documents <i class="fa fa-upload"></i></button>

        </div>
      </div>
    </td>
    <td data-th="Subtotal" class="text-center">{{ subtotal | toCurrency }}</td>
    <td class="actions" data-th="">
      <button class="btn btn-danger btn-sm" @click="removeItem"><i class="fa fa-trash-o"></i></button>
    </td>
  </tr>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import { uploadBytesResumable, ref, getDownloadURL } from "firebase/storage";
import {storage} from "@/firebase";
  export default {
    data() {
      console.log(this.cartItem.sasDetails)
      console.log(this.cartItem.tmpSasDetails)
      return {
        selected: null,
        sasNumber: this.cartItem.sasDetails? this.cartItem.sasDetails.sas_number : (this.cartItem.tmpSasDetails? this.cartItem.tmpSasDetails.sas_number: null),
        file: null,
        uploadProgress:0,
        editSasDetails: (!(this.cartItem.sasDetails || this.cartItem.tmpSasDetails)),
        docUrl: null
      }
    },
    props: ['cartItem'],
    computed: {
      ...mapGetters([
        'products',
          'sasNumbers'
      ]),
      options() {
        const options = []
        for (const product of this.products) {
          options.push({
            value: product,
            text: product.title
          })
        }
        return options
      },
      selectedProduct:{
        get: function () {
          return this.products.find(element => element.id === this.cartItem.id)
        },
        set: function (newItem) {
          console.log(newItem)
          this.cartItem.id = newItem.id
          this.cartItem.description = newItem.description
          this.cartItem.image_url = newItem.image_url
          this.cartItem.price = newItem.price
          this.cartItem.quantity = newItem.quantity
          this.cartItem.status = newItem.status
          this.cartItem.thumbnail_url = newItem.thumbnail_url
          this.cartItem.title = newItem.title
        }
      },
      subtotal() {
        // console.log(this.cartItem)
        return this.cartItem.id ? this.cartItem.quantity * this.cartItem.price : 0;
      },
      validateSASNumber() {
        // console.log(this.sasNumbers)
        const value = this.sasNumbers.find(element => element.sas_number === this.sasNumber)

        // console.log(value)
        return !!value;
      },
      fileAdded() {
        return !!this.file;

      }
    },
    methods: {
      ...mapActions(['updateCart', 'removeItemInCart', 'updateCartSasDetails','updateCartTmpSasDetails']),
      removeItem() {
        let vm = this;
        this.removeItemInCart({
          orderItemId: vm.orderItemId
        });
      },
      updateQuantity(event) {
        let vm = this;
        this.updateCart({
          item: vm.cartItem,
          quantity: parseInt(event.target.value),
          isAdd: false,
          orderItemId: vm.cartItem.orderItemId
        });
      },
      updateOrder( newItem) {
        // console.log(newItem)
        // this.cartItem = {...this.cartItem, ...newItem}


        let vm = this;
/*        this.updateCart({
          item: newItem,
          quantity: 1,
          isAdd: false,
          orderItemId: vm.cartItem.orderItemId
            });*/
        this.$emit("updateItem", {
          item: newItem,
          quantity: 1,
          isAdd: false,
          orderItemId: vm.cartItem.orderItemId
        });

      },
      setSasDetails() {
        const sasDetails = this.sasNumbers.find(element => element.sas_number === this.sasNumber)
        if(sasDetails) {
          this.cartItem.sasDetails = sasDetails
          this.updateCartSasDetails({
            orderItemId: this.cartItem.orderItemId,
            sasDetails: sasDetails
          })
        }
        this.editSasDetails = false
      },

      updateTmpSasDetails() {
        const tmpSasDetails = {
          sas_number :this.sasNumber,
          doc_url : this.docUrl
        }
        this.cartItem.tmpSasDetails = tmpSasDetails
        this.updateCartTmpSasDetails({
          orderItemId: this.cartItem.orderItemId,
          tmpSasDetails: tmpSasDetails
        })
        this.editSasDetails = false
      },
     uploadDocument() {
       let vm = this;
       this.tgaDoc=null;
        this.uploadProgress=0;
       // console.log(event)
       // console.log(this.file)
       // const file = event.target.files[0]
       const file = vm.file
        // const storageRef=firebase.storage().ref(`${this.imageData.name}`).put(this.imageData);
        const storageRef = ref(storage, 'tga_approvals/' + (new Date).valueOf() + '/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file.file)

        uploadTask.on('state_changed',
            snapshot=>{
              this.uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            },
            error=>{console.log(error.message)},
            ()=>{
              this.uploadProgress=100;
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                this.docUrl = downloadURL
              });
            }
        );
      },
      enableEdit() {
        this.editSasDetails = true
      }

    }
  }
</script>
