import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { firebaseListener } from './firebase';
import './assets/styles/app.scss'
import vuetify from '@/plugins/vuetify'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import moment from 'moment';



// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/scss/bootstrap.scss';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

Vue.config.productionTip = false
firebaseListener(authStatusChange);

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  let formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
});
Vue.filter('formatFirebaseDate', function(value) {
  if (value) {
    return moment(String(new Date(value.seconds *1000))).format('YYYY MMM DD')
  }
});
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

function authStatusChange(loggedIn, user) {
  if (store) {
    store.commit('AUTH_STATUS_CHANGE');
    if (user) {
      store.dispatch('getShoppingCart', {uid: user.uid, currentCart: store.getters.cartItemList});
    }
  }

}