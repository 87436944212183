const state = {
	cartItemList: [],
	status:'draft',
	statusHistory:[]
}

const mutations = {
	'UPDATE_CART' (state, {item, orderItemId, quantity, isAdd}) {
		let record = state.cartItemList.find(element => element.orderItemId === orderItemId);
		// const index = state.cartItemList.findIndex(element => element.orderItemId === orderItemId);

		// let record = index>-1? state.cartItemList[index]: undefined;
		console.log(record)

		if (record) {
			if (item && item.id !== record.id) {
				// state.cartItemList[index] = {...record, ...item}
				// record = state.cartItemList[index]
				record.id = item.id
				record.description = item.description
				record.image_url = item.image_url
				record.price = item.price
				record.quantity = item.quantity
				record.status = item.status
				record.thumbnail_url = item.thumbnail_url
				record.title = item.title

			}
			if (isAdd) {
				record.quantity += quantity;
			} else {
				record.quantity = quantity;
			}
		} else {
			state.cartItemList.push({
				...item,
				quantity,
				orderItemId
			});
		}
		console.log(state.cartItemList)
	},
	'SET_CART' (state, order) {
		state.status = order.status
		state.cartItemList = order.cartItemList
		state.statusHistory = order.statusHistory
	},
	'REMOVE_CART_ITEM' (state, {orderItemId}) {
		const record = state.cartItemList.find(element => element.orderItemId === orderItemId);
		state.cartItemList.splice(state.cartItemList.indexOf(record), 1);
	},
	'UPDATE_CART_SAS_DETAILS' (state, {orderItemId, sasDetails}) {
		console.log(sasDetails)
		const record = state.cartItemList.find(element => element.orderItemId === orderItemId);
		if(record) {
			record.sasDetails = sasDetails
			record.tmpSasDetails = null
		}
	},
	'UPDATE_CART_TMP_SAS_DETAILS' (state, {orderItemId, tmpSasDetails}) {
		console.log(tmpSasDetails)
		const record = state.cartItemList.find(element => element.orderItemId === orderItemId);
		if(record) {
			record.sasDetails = null
			record.tmpSasDetails = tmpSasDetails
		}
	}
}

const actions = {
	clearCart: ({commit}) => {
		commit('SET_CART', {
			status:'draft',
			cartItemList: [],
			statusHistory: []
		});
	}
}

const getters = {
	cartItemList: (state) => {
		console.log(state.cartItemList)
		return state.cartItemList;
	},
	cartStatus: (state) => {
		return state.status
	},
	cartStatusHistor: (state) => {
		return state.statusHistory
	},
	cartValue: (state) => {
		let res = 0;
		state.cartItemList.map(item => {
			if(item.id) {
				res += item.price * item.quantity;
			}
		});
		return res;
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}
