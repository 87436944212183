<template>
  <div class="row">
    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
      <div v-if="userDetail">
        <v-simple-table v-if="userDetail"
                        dense>
          <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              Value
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="font-weight-bold">Status</td>
            <td style="text-transform: capitalize;">{{ userDetail.status }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">Email</td>
            <td>{{ userDetail.email }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">Name</td>
            <td>{{ userDetail.firstName }} {{ userDetail.lastName }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Phone</td>
            <td>{{ userDetail.phone }}</td>
          </tr>
          <tr v-if="userDetail.type && (userDetail.type==='doctor' || userDetail.type==='pharmacist')">
            <td class="font-weight-bold align-text-top">AHPRA Number</td>
            <td>{{ userDetail.ahpra }}<br>{{ userDetail.ahpraDetails.name }}<br>{{ userDetail.ahpraDetails.location }}
            </td>
          </tr>
          <tr v-if="userDetail.type && (userDetail.type==='doctor' || userDetail.type==='pharmacist')">
            <td class="font-weight-bold align-text-top">ABN Details</td>
            <td>
              {{ userDetail.abn }}<br>{{ userDetail.abnDetails.EntityName }}<br>{{ userDetail.abnDetails.EntityTypeName }}<br>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold align-text-top">Address</td>
            <td>{{ userDetail.addressLine1 }}<br
                v-if="userDetail.addressLine2">{{ userDetail.addressLine2 ? userDetail.addressLine2 : '' }}<br>{{ userDetail.suburb }}
              {{ userDetail.postCode }}
            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <v-divider/>
        <div v-if="userDetail.status==='pending'">
          <div class="row">
          <div class="col">
          <b-button class="btn btn-danger" block :disabled="isLoading " @click="changeStatus('declined')">
            <i v-if="isLoading" class="fa fa-spinner fa-spin"/>
            Decline
          </b-button>
          </div>
          <div class="col">
          <b-button class="btn btn-success" block :disabled="isLoading" @click="changeStatus('active')">
            <i v-if="isLoading" class="fa fa-spinner fa-spin"/>
            Approve
          </b-button>
          </div>
        </div>
        </div>

        <div v-if="userDetail.status==='active'">
          <b-button class="btn btn-warning" block :disabled="isLoading " @click="changeStatus('disabled')">
            <i v-if="isLoading" class="fa fa-spinner fa-spin"/>
            Disable
          </b-button>
        </div>
        <div v-if="userDetail.status==='disabled' || userDetail.status==='declined'">
          <b-button class="btn btn-success" block :disabled="isLoading " @click="changeStatus('active')">
            <i v-if="isLoading" class="fa fa-spinner fa-spin"/>
            Activate
          </b-button>
        </div>
        </div>


    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {doc, onSnapshot, updateDoc} from "firebase/firestore";
import {firestore} from "@/firebase";

export default {
  data() {
    return {
      uid: null,
      userDetail: null,
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['clearMessage', 'addMessage', 'registerByEmail', 'createUserRegistration']),
    async changeStatus(status) {
      console.log(status)
      if (this.uid) {
        this.isLoading = true
        const docRef = doc(firestore, "users", this.uid)
        await updateDoc(docRef, {
          status: status
        });
        this.isLoading = false
      }
    }
  },

  async created() {
    this.uid = this.$route.query.id
    console.log(this.uid)
    if (this.uid) {
      onSnapshot(doc(firestore, "users", this.uid), (doc) => {
        this.userDetail = doc.data()
        console.log(this.userDetail)

      });

    }
  }
}
</script>
