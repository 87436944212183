<template>
  <div class="row">
    <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
      <h3 class="text-center">PLEASE COMPLETE THIS FORM</h3>
      <label>Please complete the following fields and submit your registration. If you have any questions, call us 1300 Sativa (1300 728 482)</label>
      <v-divider/>
      <div v-if="registrationSubmitted">
      <label style="color: green">Thank you for submitting registration request. Our team will assess your application and will be in contact with you shortly. </label>
        <a href="https://sativite.com.au/">Home</a>
      </div >
        <b-form v-else id="register-form" role="form" @submit="onSubmit">
        <div v-if="type==='doctor' || type==='pharmacist'" class="form-group">
          <label for="ahpra">AHPRA Registration Number <i style="color:red">*</i>:</label>
          <b-input-group class="mt-3">
          <b-form-input
            name="ahpra"
            id="ahpra"
            class="form-control"
            :placeholder="type==='doctor' ? 'MEDxxxxxxxxxx' : 'PHAxxxxxxxxxx'"
            v-model="ahpra"
            :state="isValidAhpra"
            onchange=""
            required
          /><template #append>
            <button class="btn btn-info btn-sm" @click="validateAPHRA" :disabled="isValidatingAhpra">
              <i v-if="isValidatingAhpra" class="fa fa-spinner fa-spin" />
              Check
            </button>
          </template>
          </b-input-group>
<!--          <b-form-text v-if="ahpraDetails && ahpraDetails.Results && ahpraDetails.Results.length > 0" id="input-live-help">{{ahpraDetails.Results[0].name}}</b-form-text>-->
          <b-form-text v-if="ahpraDetails" id="input-live-help">{{ahpraDetails.name}}</b-form-text>
        </div>
        <div v-if="type==='doctor' || type==='pharmacist'" class="form-group">
          <label for="abn">ABN:</label>
          <b-input-group class="mt-3">
          <b-form-input
            name="abn"
            id="abn"
            class="form-control"
            placeholder="xxxxxxxxx"
            v-model="abn"
            :state="isValidAbn"
          /><template #append>
            <button class="btn btn-info btn-sm" @click="validateABN" :disabled="isValidatingAbn">
              <i v-if="isValidatingAbn" class="fa fa-spinner fa-spin" />
              Check
            </button>
          </template>
          </b-input-group>
          <b-form-text v-if="abnDetails && abnDetails.EntityName" id="input-live-help">{{abnDetails.EntityName}}</b-form-text>
        </div>
        <div class="row">
          <div class="col-md">
            <label>First Name <i style="color:red">*</i>:</label>
            <b-form-input
                aria-label="First Name"
                type="text"
                name="firstName"
                id="firstName"
                class="form-control"
                placeholder="First Name"
                v-model="firstName"
                required
            />
          </div>
          <div class="col-md">
            <label>Last Name <i style="color:red">*</i>:</label>
            <b-form-input
                aria-label="Last Name"
                type="text"
                name="lastName"
                id="lastName"
                class="form-control"
                placeholder="Last Name"
                v-model="lastName"
                required
            />
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email <i style="color:red">*</i>:</label>
          <b-form-input
            aria-label="Email"
            type="email"
            name="email"
            id="email"
            class="form-control"
            placeholder="Email Address"
            v-model="email"
            required
          />
        </div>
        <div class="form-group">
          <label for="phone">Phone Number <i style="color:red">*</i>:</label>
          <b-form-input
            aria-label="Phone Number"
            type="tel"
            name="phone"
            id="phone"
            class="form-control"
            placeholder="Phone Number"
            v-model="phone"
            required
          />
        </div>
        <div class="form-group">
          <label for="addressLine1">Address Line 1 <i style="color:red">*</i>:</label>
          <b-form-input
            aria-label="Address Line 1"
            type="text"
            name="addressLine1"
            id="addressLine1"
            class="form-control"
            placeholder="Address Line 1"
            v-model="addressLine1"
            required
          />
        </div>
        <div class="form-group">
          <label for="addressLine2">Address Line 2 :</label>
          <b-form-input
            aria-label="Address Line 2"
            type="text"
            name="addressLine2"
            id="addressLine2"
            class="form-control"
            placeholder="Address Line 2"
            v-model="addressLine2"
          />
        </div>
        <div class="row">
          <div class="col-md">
            <label>Suburb <i style="color:red">*</i>:</label>
            <b-form-input
                aria-label="Suburb"
                type="text"
                name="suburb"
                id="suburb"
                class="form-control"
                placeholder="Suburb"
                v-model="suburb"
                required
            />
          </div>
          <div class="col-md">
            <label>Post Code <i style="color:red">*</i>:</label>
            <b-form-input
                aria-label="Post Code"
                type="text"
                name="postCode"
                id="postCode"
                class="form-control"
                placeholder="Post Code"
                v-model="postCode"
                required
            />
          </div>
        </div>
        <div class="form-group">
          <label >State <i style="color:red">*</i>:</label>
          <b-form-select
              aria-label="State"
              name="state"
              id="state"
              class="form-control"
              :options="states"
              v-model="state"
              required
          />
        </div>
          <div v-if="type!=='doctor' && type!=='pharmacist'">
          <div>
          <b-form-checkbox
              id="isShippingSameAsBilling"
              v-model="isShippingSameAsBilling"
              name="isShippingSameAsBilling"
              value='yes'
              unchecked-value= 'no'
          >
            Is your shipping address the same as your residential address? <i style="color:red">*</i>
          </b-form-checkbox>
        </div>
        <br>
        <div v-if="isShippingSameAsBilling==='no'">
          <label >Shipping Address </label>
          <div class="form-group">
            <label for="shpAddressLine1">Address Line 1 <i style="color:red">*</i>:</label>
            <b-form-input
                aria-label="Address Line 1"
                type="text"
                name="shpAddressLine1"
                id="shpAddressLine1"
                class="form-control"
                placeholder="Address Line 1"
                v-model="shpAddressLine1"
                required
            />
          </div>
          <div class="form-group">
            <label for="shpAddressLine2">Address Line 2 :</label>
            <b-form-input
                aria-label="Address Line 2"
                type="text"
                name="shpAddressLine2"
                id="shpAddressLine2"
                class="form-control"
                placeholder="Address Line 2"
                v-model="shpAddressLine2"
            />
          </div>
          <div class="row">
            <div class="col-md">
              <label>Suburb <i style="color:red">*</i>:</label>
              <b-form-input
                  aria-label="Suburb"
                  type="text"
                  name="shpSuburb"
                  id="shpSuburb"
                  class="form-control"
                  placeholder="Suburb"
                  v-model="shpSuburb"
                  required
              />
            </div>
            <div class="col-md">
              <label>Post Code <i style="color:red">*</i>:</label>
              <b-form-input
                  aria-label="Post Code"
                  type="text"
                  name="shpPostCode"
                  id="shpPostCode"
                  class="form-control"
                  placeholder="Post Code"
                  v-model="shpPostCode"
                  required
              />
            </div>
          </div>
          <div class="form-group">
            <label >State <i style="color:red">*</i>:</label>
            <b-form-select
                aria-label="State"
                name="shpState"
                id="shpState"
                class="form-control"
                :options="states"
                v-model="shpState"
                required
            />
          </div>
        </div>
        </div>
        <div class="form-group">
          <b-button type="submit" class="btn btn-success" style="width: 100%" :disabled="isLoading">
            <i v-if="isLoading" class="fa fa-spinner fa-spin" />
            Register
          </b-button>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-center">
                <router-link to="/login">
                  <a>Login</a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { httpsCallable } from "firebase/functions";
import {functions} from "@/firebase";

export default {
  props: {
    type : null
  },
  data() {
    return {
      registrationSubmitted : false,
      email: '',
      isLoading: false,
      isValidAhpra: false,
      isValidatingAhpra: false,
      ahpra: '',
      ahpraDetails: null,
      isValidAbn: false,
      isValidatingAbn: false,
      abn: '',
      abnDetails: null,
      firstName: null,
      phone: null,
      lastName: null,
      addressLine1:null,
      addressLine2:null,
      suburb:null,
      postCode:null,
      state:null,
      isShippingSameAsBilling:'yes',
      shpAddressLine1:null,
      shpAddressLine2:null,
      shpSuburb:null,
      shpPostCode:null,
      shpState:null,
      states:[
        {value: null, text:'Please select a state'},
        {value: 'Australian Capital Territory', text:'Australian Capital Territory'},
        {value: 'New South Wales', text:'New South Wales'},
        {value: 'Northern Territory', text:'Northern Territory'},
        {value: 'Queensland', text:'Queensland'},
        {value: 'South Australia', text:'South Australia'},
        {value: 'Tasmania', text:'Tasmania'},
        {value: 'Victoria', text:'Victoria'},
        {value: 'Western Australia', text:'Western Australia'},
      ]
    }
  },
  methods: {
    ...mapActions(['clearMessage', 'addMessage', 'registerByEmail', 'createUserRegistration']),
    onSubmit(event) {
      event.preventDefault()
      let isValid = true;
      if (!this.isValidAhpra) {
        isValid = false;
        let message_obj = {
          message: 'Please provide a valid AHPRA number and click Check to validate it',
          messageClass: "danger",
          autoClose: true
        }
        this.addMessage(message_obj);
      }
      if(this.abn && !this.isValidAbn) {
        isValid=false
        let message_obj = {
          message: 'Please provide a valid ABN number and click Check to validate it',
          messageClass: "danger",
          autoClose: true
        }
        this.addMessage(message_obj);

      }
      if(isValid) {
        this.isLoading = true
        let data = {
          email: this.email,
          ahpra: this.ahpra,
          ahpraDetails: this.ahpraDetails,
          abn: this.abn,
          abnDetails: this.abnDetails,
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          addressLine1:this.addressLine1,
          addressLine2:this.addressLine2,
          suburb:this.suburb,
          postCode:this.postCode,
          state:this.state,
          isShippingSameAsBilling:this.isShippingSameAsBilling,
          shpAddressLine1: this.isShippingSameAsBilling === 'yes' ? this.addressLine1 : this.shpAddressLine1,
          shpAddressLine2: this.isShippingSameAsBilling === 'yes' ? this.addressLine2 : this.shpAddressLine2,
          shpSuburb: this.isShippingSameAsBilling === 'yes' ? this.suburb : this.shpSuburb,
          shpPostCode: this.isShippingSameAsBilling === 'yes' ? this.postCode : this.shpPostCode,
          shpState: this.isShippingSameAsBilling === 'yes' ? this.state : this.shpState,
          status: 'pending',
          type: this.type
        }
        // this.registerByEmail(data).then(() => {
        this.createUserRegistration({registrationDetails: data}).then(() => {
          this.clearMessage();
          this.registrationSubmitted = true
          // this.$router.push({name: 'mainpage'});
        })
            .catch((error) => {
              // console.log('register error', error);
              let message_obj = {
                message: error.message,
                messageClass: "danger",
                autoClose: true
              }
              this.addMessage(message_obj);
            }).then(() => {
          this.isLoading = false
        })
      }
    },

    async validateAPHRA() {
      if (this.ahpra || this.ahpra.length > 6) {
        this.isValidatingAhpra = true
        const validateAPHRA = httpsCallable(functions, 'validateAPHRA');
        validateAPHRA({ahpra:this.ahpra}).then(response => {
          if(response.data.totalResults > 0 &&
              this.ahpra.toUpperCase()===response.data.Results[0].id &&
              ((this.type==='pharmacist' && this.ahpra.toUpperCase().startsWith('PHA')) ||
                  (this.type==='doctor' && this.ahpra.toUpperCase().startsWith('MED')))) {
              this.isValidAhpra = true
              this.ahpraDetails = response.data.Results[0]
          } else {
            this.isValidAhpra = false
            this.ahpraDetails = null
          }

        }).finally(() =>{
          this.isValidatingAhpra = false
        })

      }
    },

    async validateABN() {
      if (this.abn || this.abn.length > 10) {
        this.isValidatingAbn = true
        const validateABN = httpsCallable(functions, 'validateABN');
        validateABN({abn:this.abn}).then(response => {
          // console.log(response.data)
          this.isValidAbn = response.data.AbnStatus === 'Active';
          this.abnDetails = response.data
        }).finally(() =>{
          this.isValidatingAbn = false
        })

      }
    }

  }
}
</script>
